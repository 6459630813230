import { useWindowSize } from '@react-hookz/web';
import { CategoriesShowcaseDesktop, CategoriesShowcaseMobile } from 'components/categories';
import CategoryCarousel2 from 'components/categories/CategoryCarousel2'
import { BlockDivider, PageMeta } from 'components/common';
import { CallbackCTA, VKCTA } from 'components/cta';
import { useBookmarks } from 'hooks';
import dynamic from 'next/dynamic';
import { ProductsService, StoriesService } from 'services/api';
import ReviewsService from 'services/api/reviews.service';


const ActiveOrders = dynamic(() => import('components/common').then(module => module.ActiveOrders), {
  ssr: false
});

const Stories = new StoriesService();
const Products = new ProductsService();

export async function getServerSideProps(context) {
  const [ stories, catalog, reviews ] = await Promise.all([
    Stories.getMany(),
    Products.getCatalog(),
    ReviewsService.getMany({ count: 10, isWithPhotosOnly: true }),
  ]);

  const mapProducts = (products) => {
    return products.map(({ id, slug, images, title, outOfStock, price, fullSlug }) => ({
      id,
      images,
      title,
      outOfStock,
      price,
      slug,
      fullSlug
    }));
  };

  return {
    props: {
      reviews,
      stories,
      catalog: catalog.reduce((acc, group) => {

        if (group.totalCount >= 5) {
          acc.push({
            ...group,
            products: mapProducts(group.products)
          });
        }

        return acc;
      }, [])
    }
  };
}

export default function HomePage({ activeOrders, catalog, reviews, stories, city }) {
  const [ bookmarks, setBookmarks ] = useBookmarks();
  const { width } = useWindowSize();

  const addToBookmarks = (id) => {
    if (!bookmarks?.includes(id)) {
      setBookmarks([
        ... (bookmarks || []),
        id
      ]);
    }
  }

  const removeFromBookmarks = (id) => {
    if (bookmarks?.includes(id)) {
      setBookmarks(bookmarks.filter(bid => bid !== id));
    }
  }

  const isBookmarked = (id) => {
    return bookmarks.includes(id);
  }

  const components = [
    <PageMeta key="meta"/>,
    // <CategoriesShowcaseDesktop key="categories_showcase_desktop"/>,
  ];


  if (activeOrders?.length) {
    components.push(
      <ActiveOrders key="active_orders" orders={activeOrders}/>
    )
  }

  // components.push(
  //   <CategoriesShowcaseMobile key="categories_showcase_mobile"/>
  // );

  // components.push(
  //   <BlockDivider key="stories_divider"/>
  // );
  // if (stories?.length) {
  //   components.push(
  //     <StoriesList key="stories" stories={stories}/>
  //   );
  // }

  let renderedCategoriesCount = 0;
  for (const { category, products, totalCount } of catalog) {
    if (category.metadata.some(({ key, value }) => (key === 'hidden_from_homepage' && value === true))) {
      continue;
    }

    const isOnline = category.metadata?.some(({ key, value }) => (key === 'is_online' && value));

    components.push(
      <CategoryCarousel2
        key={`category_${category.slug}`}
        index={renderedCategoriesCount}
        isOnline={isOnline}
        category={category}
        products={products}
        windowWidth={width}
        bookmarks={{
          isBookmarked,
          add: addToBookmarks,
          remove: removeFromBookmarks,
        }}
        totalCount={totalCount}
      />
    );

    if (renderedCategoriesCount !== catalog.length - 1) {
      components.push(<BlockDivider key={`category_${category.slug}_separator`}/>);
    }

    renderedCategoriesCount += 1;

    // if (renderedCategoriesCount === 3) {
    //   components.push(
    //     <ReviewShowcase key="reviews_showcase" reviews={reviews}/>
    //   );
    //   components.push(<BlockDivider key="first_cta_separator"/>);
    // } else
    if (renderedCategoriesCount === 3) {
      components.push(
        <div key="cta_1" className="md:my-6 mx-6 md:-mx-2 flex-col grid lg:grid-cols-4 flex flex-row overflow-clip">
          <CallbackCTA/>
          <VKCTA className="hidden lg:flex"/>
        </div>
      );
      components.push(<BlockDivider key="second_cta_separator"/>);
    } else if (renderedCategoriesCount === 6) {
      components.push(<VKCTA key="cta_2" className="block lg:hidden"/>);
      components.push(<BlockDivider key="third_cta_separator"/>);
    }
  }

  components.push(<BlockDivider key="end_separator"/>);

  return components;
}

HomePage.showStories = 'top';
